* {
  margin: 0;
  padding: 0;
}

.logo {
  width: 100px;
  height: 50px;
}
.lmezXe img {
  margin-right: 0.75rem;
  width: 9.5rem !important;
}
.gyREIz {
  --tw-bg-opacity: 1;
  margin-left: -2rem !important;
  margin-right: -2rem !important;
  /* background-color: rgb(0 32 101) !important; */
  background-image: url("../../images/20023293.jpg");
  background-size: cover;
  padding-left: 2rem;
  /* filter: brightness(0.5); */
  padding-right: 2rem;
  --tw-text-opacity: 1;
  color: rgb(247 250 252 / var(--tw-text-opacity));
}
.gyREIz {
  margin-top: 0rem !important;
  margin-bottom: 2.5rem;
}
.truss {
  height: 70px;
  background: #000;
  width: 100vw;
  margin-left: -2rem !important;
  margin-right: -2rem !important;
  overflow: hidden;
}
.truss img {
  height: 100%;
  object-fit: cover;
}

.gDbhMd .BlogIndex__Post-sc-11jbi6t-4 {
  padding-right: 1rem;
  display: flex !important;
  flex-direction: column !important;
  /* flex-direction: row !important; */
}
