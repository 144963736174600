@import url('https://fonts.googleapis.com/css2?family=Oleo+Script+Swash+Caps:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.lmezXe img {
  margin-right: 0.75rem;
  width: 9.5rem !important;
}
.gyREIz {
  --tw-bg-opacity: 1;
  margin-left: -2rem !important;
  margin-right: -2rem !important;
  /* background-color: rgb(0 32 101) !important; */
  background-image: url("./images/20023293.jpg");
  background-size: cover;
  padding-left: 2rem;
  /* filter: brightness(0.5); */
  padding-right: 2rem;
  --tw-text-opacity: 1;
  color: rgb(247 250 252 / var(--tw-text-opacity));
}
.gyREIz {
  margin-top: 0rem !important;
  margin-bottom: 2.5rem;
}
.truss {
  height: 70px;
  background: #000;
  width: 100vw;
  margin-left: -2rem !important;
  margin-right: -2rem !important;
  overflow: hidden;
}
.truss img {
  height: 100%;
  object-fit: cover;
}

.gDbhMd .BlogIndex__Post-sc-11jbi6t-4 {
  padding-right: 1rem;
  display: flex !important;
  flex-direction: column !important;
  /* flex-direction: row !important; */
}

video {
  position: absolute;
  top: 0;
  filter: brightness(0.5);
  left: 0;
  height: 100vh;
  min-width: 100%;
  object-fit: cover;
  z-index: -100;
}
.eMBGtn img {
  margin-right: 0.75rem;
  min-width: 120px !important;
  min-height: 50px !important;
}
.dyYDeT {
  color: rgb(0, 162, 237);
}
.jwdah {
  background: rgb(0, 162, 237) !important;
}
.cqaZle {
  background-image: url(https://cloud.appwrite.io/v1/storage/buckets/6620f76…/files/664354e…/view?project=661d51c…&mode=admin);
  height: 16rem;
  width: 16rem;
  border-radius: 0.25rem;
  background-size: cover !important;
  background-position: center center;
}

.aetbs {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 5rem !important;
}
#emitter {
  visibility: hidden;
  background-color: #222;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 40%;
  left: 0%;
}
.dot-container {
  position:absolute;
  left:0;
  top:0;
  overflow:visible;
  z-index:5000;
  pointer-events:none;
}
.dot {
  position: absolute;
  pointer-events: none; /*performance optimization*/
}

.eMBGtn img {
  margin-right: .75rem;
  min-height: 50px !important;
  min-width: 142px !important;
}

.oleo-script-swash-caps-regular {
  font-family: "Oleo Script Swash Caps", system-ui;
  font-weight: 400;
  font-style: normal;
}

.oleo-script-swash-caps-bold {
  font-family: "Oleo Script Swash Caps", system-ui;
  font-weight: 700;
  font-style: normal;
}

.djoYkS{
  font-family: "Oleo Script Swash Caps", system-ui !important;
  font-weight: 700 !important;
  font-style: normal !important;
}
.fmdTYx img {
    margin-right: 0.75rem !important;
    width: 8.5rem !important;
}
.bHsVmK {
  font-family: "Oleo Script Swash Caps", system-ui !important;
  font-weight: 700 !important;
  font-style: normal !important;
}

video {
  max-width: 100%;
  height: 100vh !important;
}
.jNrrya {
  box-shadow: 0px 1px 9px -2px !important;
}

.lphIcP {
  position: absolute;
  inset: 0px;
  filter: brightness(0.2);
  background-image: url(https://cloud.appwrite.io/v1/storage/buckets/6620f76347a6c75a350d/files/664ee4850010c4c32dd5/view?project=661d51c7e4d47fa7d45d&mode=admin)!important;
  background-position: center !important;
  overflow: hidden;
  border-radius: 0.5rem;
}

.hfauOs {
  position: absolute;
  inset: 0px;
  background: white;
  overflow: hidden;
  box-shadow: 0px 0px 12px -2px;
  border-radius: 0.5rem;
}

.bpw-floating-button {
  /* Aligns the button to the right side of the container */
  float: right;
  /* Sets the fill color of the button icon */
  fill: rgb(255, 255, 255);
  /* Changes the cursor to a pointer when hovering over the button */
  cursor: pointer;
  /* Removes the outline around the button when clicked */
  outline: none;

  /* Centers the icon inside the button element */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Sets the z-index to ensure the button is on top of other elements */
  z-index: 100;
  /* Sets the width and height of the button */
  width: 52px;
  height: 52px;
  /* Changes the cursor to a pointer when hovering over the button */
  cursor: pointer;
  /* Rounds the corners of the button */
  border-radius: 50%;
  /* Adds a shadow effect to the button */
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  /* Clips any content that overflows the button */
  overflow: hidden;

  /* Removes padding and borders from the button */
  padding: 0;
  border: none;
  background-clip: padding-box;

  /* Adds a transition effect to the button when its size changes */
  transition: width 1s, height 1s;
}
.bpw-layout {
  width: 360px !important;
  height: 60vh !important;
  border-radius: 10px;
  right: 10px;
  bottom: 5px;
  border: 1px;
}